import { Box, Button, IconButton, Paper, Typography, styled as muiStyled } from "@mui/material";
import styled from "styled-components";
import { altText, captionColor, disabledControl, hexToRgbA, hightlightBackground, lightBackground, primary, primaryText, secondaryText } from "./colors";
import { PrimaryButton } from "./buttons";

export const MenuItemTitle = styled(Typography)`
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
            line-clamp: 1; 
    -webkit-box-orient: vertical;
`;

export const MenuItemDescription = styled(Typography)`
    overflow: hidden !important;
    display: -webkit-box !important;
    -webkit-line-clamp: ${props => props.theme.maxLines}; /* number of lines to show */
            line-clamp:  ${props => props.theme.maxLines};
    -webkit-box-orient: vertical;
`;

export const MenuItemImage = styled.img`
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
    object-position: center;
    border-radius: 5px;
    animation: fadeIn 0.5s ease;
` as any;

export const MenuItemDesktopImage = styled.img`
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
    object-position: center;
    animation: fadeIn 0.5s ease;
`;

export const MenuItemModalImage = styled.img`
    width: 100%;
    aspect-ratio: 16/9;
    object-fit: cover;
    object-position: center;
    animation: fadeIn 0.5s ease;
`;

export const MenuSliderGrid = styled.div`
    display: grid;
    grid-template-columns: auto auto;
`;

export const MenuSliderIconContainer = styled.div`
    z-index: 100;
    box-shadow: 4px 0 2px -1.5px ${hexToRgbA(captionColor, 0.25)};
`;

export const MenuCategorySliderContainer = styled.div`
    width: 100%;
    overflow-x: scroll;
    display: flex;
    -ms-overflow-style: none;
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
        display: none; /* Chrome, Safari */ 
    }
    height: 1.625rem;
`;

export const MenuCategoryColumnBlur = styled.div`
    position: absolute;
    color: transparent;
    background: white;
    pointer-events: none;
    background: rgba(255, 255, 255, 0.14);
    background: linear-gradient(90deg, rgba(212,207,207,0.4) 0%, rgba(212,207,207,0.30) 25%, rgba(212,207,207,0) 100%);
    width: 15px;
    height: 100%;
    zIndex: 1000;
`;

export const MenuCategorySliderItem = styled.div`
    white-space: nowrap;
    margin-left: 0.6rem;
    margin-right: 0.6rem;
`;

export const MenuCategorySliderButton = styled.div`
    font-size: 0.875rem;
    cursor: pointer;
    font-weight: bold;
    height: 20px;
    padding-top: 6px;
    padding-left: 10px;
    padding-right: 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background: ${props => props.theme.isSelected ? primary : "transparent"}; 
    color: ${props => props.theme.isSelected ? altText : primaryText};
    transition: background 0.2s linear;
    will-change: background;
`;

export const MenuDrawerContainer = styled.div`
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    background: white;
    overflow: hidden;
`;

export const MenuDrawerImage = styled.img`
    width: 100%;
    aspect-ratio: 16/9;
    object-fit: cover;
    object-position: center;
`;

export const MenuDrawerCloseIconButton = muiStyled(IconButton)`
    position: absolute;
    top: 10px;
    right: 10px;
    color: white;
    opacity: 0.9;
    z-index: 1000;
    background: ${primary};
    padding: 4px;
    &:hover{
        background: #68ba5f;
    }
`;

export const MenuCloseIconButton = muiStyled(IconButton)`
    opacity: 0.9;
    margin-top: 10px;
    margin-right: 10px;
    z-index: 1000;
    background: ${primary};
    padding: 4px;
    &:hover{
        background: #68ba5f;
    }
`;

export const MenuDrawerFooter = styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.6);
    background: white;;
`;

export const ViewBottomButton = styled(Button)`
    position: fixed !important;
    bottom: 0;
    left: 0 !important;
    width: 100% !important;
    background: ${primary} !important;
    text-transform: none !important;
    border-radius: 0 !important;
`;

export const MenuSelectorTitleHeader = styled.div`
    color: ${props => props.theme.sticky ? secondaryText : primaryText};
    width: 100%;
    box-shadow: ${props => props.theme.sticky ? "0 0 5px 0 rgba(0,0,0,0.6)" : "none"};
    z-index: 100;
    position: ${props => props.theme.sticky ? "fixed" : "relative"};
    top: ${props => props.theme.sticky ? props.theme.drawerTop + "px" : "auto"};
    transition: .25s background;
    
`;

export const MenuOpenCategoryButton = styled(IconButton)`
  svg{
    font-size: 1.4rem !important;
  }
  padding: 0 !important;
`;

export const GuideButtonContainer = muiStyled(Box)`
    position: fixed;
    bottom: 0;
    width: 100%; 
    text-align: center; 
`;

export const GuideButton = muiStyled(PrimaryButton)`
    margin-left: 1rem; 
    width: calc(100% - 2rem);
    margin-right: 1rem;
`;

export const DesktopItemContainer = styled.div`
    transition: 0.25s all;
    position: relative;
    &:hover{
        scale: 1.02;
        .overlay {
          background: ${hexToRgbA(primary, 0.1)};
        }
    }
`;

export const DesktopItemPaper = muiStyled(Paper)`
    overflow: hidden;
    border-radius: 8px;
    cursor: pointer;
`;

export const DesktopMenuItemOverlay = styled.div`
    position: absolute; 
    top: 0;  
    width: 100%; 
    height: 100%;
    pointer-events: none;
    transition: 0.25s background;
`;