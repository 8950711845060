import React, { useContext, useEffect, useRef } from 'react'
import { HeaderCildrenContainer, HeaderTitleContainer, HeaderTitleContent, RestaurantHeaderContainer } from '../../styles/navigation'
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { secondaryText } from '../../styles/colors';
import { BoldSpan } from '../../styles/spans';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useLocation, useNavigate } from 'react-router-dom';
import { appName, menuPageRoute, usePathReconstructor } from '../../config/pageRoutes';
import RestaurantContext from '../../contexts/RestaurantContext';
import LangSelector from '../lang/LangSelector';
import { ThemeProvider } from 'styled-components';
import useScroll from '../../hooks/useScroll';
import { restaurantHeaderId, rootRestoPageId } from '../../config/layout';
import { getHeaderHeight, getHeaderPaddingTop } from '../../config/header';
import SessionContext from '../../contexts/SessionContext';

type Props = {
    children?: any;
    displayBack?: boolean;
}

const RestaurantHeader = (props: Props) => {
    const navigate = useNavigate();
    const { copyParamsFromPath } = usePathReconstructor();
    const { restaurant } = useContext(RestaurantContext);
    const location = useLocation();
    const [theme, setTheme] = React.useState({ height: 156, paddingTop: 56 });
    const { registerScrollListener, removeScrollListener, getScrollTop } = useScroll();
    const headerRef = useRef<HTMLDivElement>(null);
    const { locationStack } = useContext(SessionContext);

    useEffect(() => {
        registerScrollListener(onScroll);
        const firstLocationHash = locationStack.current[0];
        const notFirstPage = (firstLocationHash != null && firstLocationHash !== location.key) || locationStack.current.length > 1;
        if(notFirstPage && headerRef.current !== null) {
            headerRef.current.style.animation = "none";
        }
        return () => {
            removeScrollListener(onScroll);
        }
    }, [location.key]);

    function handleBackClick() {
        if (location.key === "default") {
            navigate(copyParamsFromPath(menuPageRoute));
        } else {
            navigate(-1);
        }
    }

    function onScroll() {
        const headerHeight = getHeaderHeight(getScrollTop());
        setTheme({ height: headerHeight, paddingTop: getHeaderPaddingTop(getScrollTop()) });
        const element = document.getElementById(rootRestoPageId) as HTMLElement;
        const padding = 50;
        element.style.height = `${window.innerHeight - padding - headerHeight}px`;
    }

    return (
        <ThemeProvider theme={theme}>
            <RestaurantHeaderContainer ref={headerRef} id={restaurantHeaderId}>
                <HeaderTitleContainer>
                    <HeaderTitleContent>
                        <Box px={1}>
                            <Grid container>
                                <Grid item xs={2}>
                                    <Box pt={1.5} style={{ textAlign: "left" }}>
                                        {props.displayBack && (
                                            <IconButton onClick={handleBackClick}>
                                                <ArrowBackIcon style={{ color: secondaryText, fontSize: "1.5rem" }} />
                                            </IconButton>
                                        )}
                                    </Box>
                                </Grid>
                                <Grid item xs>
                                    <Typography textAlign={"center"} fontStyle={"italic"} fontSize={"0.625rem"} color={secondaryText}>
                                        QuickPick Powered by <BoldSpan>ReservOurs</BoldSpan>
                                    </Typography>
                                    <Typography textAlign={"center"} fontWeight={600} fontSize={"1.375rem"}>
                                        {restaurant?.name}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Box pt={1.5} style={{ textAlign: "right" }} >
                                        <LangSelector />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </HeaderTitleContent>
                </HeaderTitleContainer>
                <HeaderCildrenContainer>
                    {props.children}
                </HeaderCildrenContainer>
            </RestaurantHeaderContainer>
        </ThemeProvider>
    )
}

export default RestaurantHeader