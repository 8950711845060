import React from 'react'

type Props = {
    height: number;
    width: number;
}

const VeganIcon = ({ height, width }: Props) => {
    return (
        <div style={{ background: "#84c77d", position: "relative", height: `${height}px`, width: `${width}px`, borderRadius: "50%" }}>
            <div style={{ position: "absolute", top: "55%", left: "50%", transform: "translate(-50%, -50%)" }}>
                <svg version="1.1" id="Layer_1" width={`${width * 0.65}px`} height={`${height * 0.65}px`} viewBox="0 0 117.93 122.88" >
                    <g>
                        <path fill="white" d="M104.38,97.86c5.03,6.72,9.37,12.3,11.72,18.72c2.29,6.26,3.22,9.11-1.98,2.63 c-4.84-6.04-9.36-11.91-15.98-17.48c-0.47,0.11-0.96,0.21-1.49,0.32C36.81,113.93-6.78,87.01,0.87,0 c46.1,15.96,111.38,9.48,104.62,91.25C105.25,94.29,105.02,96.37,104.38,97.86L104.38,97.86z M88.32,84.78 c-15.04-32.4-53.68-43.51-72.85-65.67C36.28,59.7,47.63,57.91,88.32,84.78L88.32,84.78L88.32,84.78z" />
                    </g>
                </svg>
            </div>
        </div>
    )
}

export default VeganIcon