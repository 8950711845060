import VeganIcon from "../components/icons/VeganIcon";
import { MenuItem } from "./MenuItem";
//@ts-ignore
import { CrustaceanIcon, EggIcon, FishIcon, GlutenIcon, PeanutIcon, SoyaIcon, MilkIcon, NutsIcon, CeleryIcon, MustardIcon, SesameIcon, SulphiteIcon, MolluscIcon, LupinIcon } from 'react-allergens';

export enum AllergenType {
    GLUTEN = "GLUTEN",
    CRUSTACEANS = "CRUSTACEANS",
    EGGS = "EGGS",
    FISH = "FISH",
    PEANUTS = "PEANUTS",
    SOYBEANS = "SOYBEANS",
    MILK = "MILK",
    NUTS = "NUTS",
    CELERY = "CELERY",
    MUSTARD = "MUSTARD",
    SESAME = "SESAME",
    SULPHUR_DIOXIDE = "SULPHUR_DIOXIDE",
    LUPIN = "LUPIN",
    MOLLUSCS = "MOLLUSCS",
    VEGAN = "VEGAN",
}

export interface Allergen {
    id: number;
    type: AllergenType;
}

export const allergenOrderDict = {
    [AllergenType.GLUTEN]: 1,
    [AllergenType.CRUSTACEANS]: 2,
    [AllergenType.EGGS]: 3,
    [AllergenType.FISH]: 4,
    [AllergenType.PEANUTS]: 5,
    [AllergenType.SOYBEANS]: 6,
    [AllergenType.MILK]: 7,
    [AllergenType.NUTS]: 8,
    [AllergenType.CELERY]: 9,
    [AllergenType.MUSTARD]: 10,
    [AllergenType.SESAME]: 11,
    [AllergenType.SULPHUR_DIOXIDE]: 12,
    [AllergenType.LUPIN]: 13,
    [AllergenType.MOLLUSCS]: 14,
    [AllergenType.VEGAN]: 15,
}

export function getIconByAllergen(allergenType: AllergenType, width = 40, height = 40) {
    switch (allergenType) {
        case AllergenType.GLUTEN:
            return <GlutenIcon width={width} height={height} wrapperStyle={{ margin: "auto" }} />;
        case AllergenType.CRUSTACEANS:
            return <CrustaceanIcon width={width} height={height} wrapperStyle={{ margin: "auto" }} />;
        case AllergenType.EGGS:
            return <EggIcon width={width} height={height} wrapperStyle={{ margin: "auto" }} />;
        case AllergenType.FISH:
            return <FishIcon width={width} height={height} wrapperStyle={{ margin: "auto" }} />;
        case AllergenType.PEANUTS:
            return <PeanutIcon width={width} height={height} wrapperStyle={{ margin: "auto" }} />;
        case AllergenType.SOYBEANS:
            return <SoyaIcon width={width} height={height} wrapperStyle={{ margin: "auto" }} />;
        case AllergenType.MILK:
            return <MilkIcon width={width} height={height} wrapperStyle={{ margin: "auto" }} />;
        case AllergenType.NUTS:
            return <NutsIcon width={width} height={height} wrapperStyle={{ margin: "auto" }} />;
        case AllergenType.CELERY:
            return <CeleryIcon width={width} height={height} wrapperStyle={{ margin: "auto" }} />;
        case AllergenType.MUSTARD:
            return <MustardIcon width={width} height={height} wrapperStyle={{ margin: "auto" }} />;
        case AllergenType.SESAME:
            return <SesameIcon width={width} height={height} wrapperStyle={{ margin: "auto" }} />;
        case AllergenType.SULPHUR_DIOXIDE:
            return <SulphiteIcon width={width} height={height} wrapperStyle={{ margin: "auto" }} />;
        case AllergenType.MOLLUSCS:
            return <MolluscIcon width={width} height={height} wrapperStyle={{ margin: "auto" }} />;
        case AllergenType.LUPIN:
            return <LupinIcon width={width} height={height} wrapperStyle={{ margin: "auto" }} />;
        case AllergenType.VEGAN:
            return <VeganIcon height={height} width={width} />
    }
}

export function allergenComparer(allergen1: Allergen, allergen2: Allergen) {
    return allergenOrderDict[allergen1.type] - allergenOrderDict[allergen2.type];
}