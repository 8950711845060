import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import { termsAndConditionsPageRoute, menuPageRoute } from './config/pageRoutes';
import React, { Suspense } from 'react';
import LocalizationHandler from './localization/LocalizationHandler';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { RestaurantProvider } from './contexts/RestaurantContext';
import { TableProvider } from './contexts/TableContext';
import { NotificationProvider } from './contexts/NotificationContext';
import { SnackbarProvider } from './contexts/SnackbarContext';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LoadingPage from './components/load/LoadingPage';
import { SessionProvider } from './contexts/SessionContext';
import { RestoLayoutProvider } from './contexts/RestoLayoutContext';
import MenuCategoryProvider from './contexts/MenuCategoryContext';

const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: 'Rubik',
    },
  },
});

function App() {
  const MenuPage = React.lazy(() => import('./pages/MenuPage'));
  // const OrderPage = React.lazy(() => import('./pages/OrderPage'));
  // const TablePage = React.lazy(() => import('./pages/TablePage'));
  //  const PayPage = React.lazy(() => import('./pages/PayPage'));
  //  const SuccessfulPaymentPage = React.lazy(() => import('./pages/SuccessfulPaymentPage'));
  const TermsAndContidionsPage = React.lazy(() => import('./pages/TermsAndConditionsPage'));

  /*
          <Route element={<OrderProvider />}>
          </Route>
  
  */

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider>
        <NotificationProvider>
          <BrowserRouter>
            <Suspense fallback={<LoadingPage />}>
              <Routes>
                <Route element={<LocalizationHandler />}>
                  <Route element={<RestaurantProvider />}>
                    <Route element={<TableProvider />}>
                      <Route element={<SessionProvider />}>
                        <Route element={<MenuCategoryProvider />}>
                          <Route element={<RestoLayoutProvider />}>
                            <Route path={menuPageRoute} element={<MenuPage />} />
                            {/*
                              
                                          <Route path={orderPageRoute} element={<OrderPage />} />
                              <Route path={tablePageRoute} element={<TablePage />} />
                              <Route path={paymentPageRoute} element={<PayPage />} />
                              <Route path={successfulPaymentPageRoute} element={<SuccessfulPaymentPage />} />
                              */}

                            <Route path={termsAndConditionsPageRoute} element={<TermsAndContidionsPage />} />
                          </Route>
                        </Route>
                      </Route>
                    </Route>
                  </Route>
                </Route>
              </Routes>
            </Suspense>
          </BrowserRouter>
        </NotificationProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
