import { Allergen, allergenComparer } from "./Allergen";
import { MenuItemTranslation } from "./Translation";
import { SerializedVatkey, VatKey, deserializeVatKey } from "./VatKey";

export interface SerializedMenuItem {
    id: number;
    priceGross: string;
    isActive: boolean;
    imageUrl: string | null;
    availabilityType: string;
    translations: MenuItemTranslation[];
    vatKey?: SerializedVatkey;
    order: number;
    allergens: Allergen[];
}

export interface MenuItem {
    id: number;
    priceGross: number;
    isActive: boolean;
    imageUrl: string | null;
    availabilityType: string;
    translations: MenuItemTranslation[];
    vatKey?: VatKey;
    order: number;
    allergens: Allergen[];
}

export function deserializeMenuItem(serialized: SerializedMenuItem): MenuItem {
    return {
        id: serialized.id,
        priceGross: parseFloat(serialized.priceGross),
        isActive: serialized.isActive,
        imageUrl: serialized.imageUrl,
        availabilityType: serialized.availabilityType,
        translations: serialized.translations,
        vatKey: serialized.vatKey != null ? deserializeVatKey(serialized.vatKey) : undefined,
        order: serialized.order,
        allergens: serialized.allergens.sort(allergenComparer) || [],
    };
}